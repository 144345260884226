import React, { useState, useEffect } from 'react';
import Nav, { DesktopNav } from '../components/Nav';
import Welcome from '../components/Welcome';
import Invoice, { InvoiceIcon } from '../components/Invoice';
import Pay, { SendIcon } from '../components/Pay';
import Account, { AccountIcon } from '../components/Account';
import Login from '../components/Login';
import { myProfile } from '../app/routes';
import { SetUpAccount } from '../components/Popups';
import Footer from '../components/Footer';

function Home() {
  const [tab, setTab] = useState('welcome');
  const [addPassword, setAddPassword] = useState(false);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    myProfile(setAddPassword);

    window.addEventListener('tab', function (e) {
      console.log(e)
      setTab(e.detail.tab);
    });
  }, []);

  const cancelAddPassword = () => {
    setAddPassword(false);
    localStorage.version = 220;
  }

  const openTab = (tab) => {
    if (tab == 'account' && !sessionStorage.userSession) {
      setLogin(true);
    }
    else setTab(tab);
  }

  return (
    <>
      {window.innerWidth < 901 ? <Nav /> : <><DesktopNav /><br /><br /><br /><br /><br /><br /></>}
      <div id='app'>
        <SendIcon open={() => openTab('pay')} />
        <InvoiceIcon open={() => openTab('invoice')} />
        <AccountIcon open={() => openTab('account')} />
        {tab === 'welcome' && <Welcome withdraw={() => setAddPassword(true)} />}
        {tab === 'invoice' && <Invoice />}
        {tab === 'pay' && <Pay />}
        {tab === 'account' && <Account />}
      </div>
      {addPassword && <SetUpAccount close={cancelAddPassword} />}
      {
        (login && !addPassword) &&
        <>
          <Login close={() => setLogin(false)} />
          <div id='darkTint' onClick={() => setLogin(false)}></div>
        </>
      }
      {window.innerWidth > 900 && <Footer />}
    </>
  );
}

export default Home;