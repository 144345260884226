import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PiLogo from '../assets/pi.png';
import logoWhite from '../assets/whiteLogo.png';
import { LoginForm } from './Login.js';

function Welcome(props) {
  const { t } = useTranslation();
  const [balance, setBalance] = useState('loading...');
  const [walletBalance, setWalletBalance] = useState('loading...');
  const [balanceDelta, setBalanceDelta] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const [history, setHistory] = useState([]);
  const [totalIn, setTotalIn] = useState(0);
  const [totalOut, setTotalOut] = useState(0);
  const [recents, setRecents] = useState([]);
  const [maxIn, setMaxIn] = useState(0);
  const [maxOut, setMaxOut] = useState(0);
  const [authed, setAuthed] = useState(false);
  const [newUser, setNewUser] = useState(false);

  const welcomeInit = () => {
    // check if user is logged in
    if (sessionStorage.userSession) setAuthed(true);

    // find change in balance since last login
    if (localStorage.oldBalance) {
      const findBalanceDelta = Number(localStorage.balance) - Number(localStorage.oldBalance);
      setBalanceDelta(findBalanceDelta);
      localStorage.oldBalance = localStorage.balance;
    }

    // find total spent per day
    if (localStorage.profile && localStorage.profile !== 'null') {
      const profile = JSON.parse(localStorage.profile);

      // set balance
      setBalance(profile.balance.toFixed(3));

      // set wallet balance
      if (localStorage.walletBalance && profile.wallet_address) {
        setWalletBalance(localStorage.walletBalance);
        setWalletAddress(`${profile.wallet_address.substring(0, 7)}....${profile.wallet_address.substring(profile.wallet_address.length - 7, profile.wallet_address.length)}`);
      }

      let history = [];
      const date = Date.parse(new Date()) - 6 * 24 * 60 * 60 * 1000;
      for (const item of profile.history) {
        if (Date.parse(item.date) > date) {
          history.push(item);
        }
      }

      let mon = 0;
      let tue = 0;
      let wed = 0;
      let thu = 0;
      let fri = 0;
      let sat = 0;
      let sun = 0;
      let monIn = 0;
      let tueIn = 0;
      let wedIn = 0;
      let thuIn = 0;
      let friIn = 0;
      let satIn = 0;
      let sunIn = 0;
      let calcTotalIn = 0;
      let calcTotalOut = 0;

      for (const item of history) {
        const day = new Date(item.date).getDay();
        switch (day) {
          case 0:
            if (item.amount > 0) sunIn += Number(item.amount);
            else sun -= Number(item.amount);
            break;
          case 1:
            if (item.amount > 0) monIn += Number(item.amount);
            else mon -= Number(item.amount);
            break;
          case 2:
            if (item.amount > 0) tueIn += Number(item.amount);
            else tue -= Number(item.amount);
            break;
          case 3:
            if (item.amount > 0) wedIn += Number(item.amount);
            else wed -= Number(item.amount);
            break;
          case 4:
            if (item.amount > 0) thuIn += Number(item.amount);
            else thu -= Number(item.amount);
            break;
          case 5:
            if (item.amount > 0) friIn += Number(item.amount);
            else fri -= Number(item.amount);
            break;
          case 6:
            if (item.amount > 0) satIn += Number(item.amount);
            else sat -= Number(item.amount);
            break;
          default:
            break;
        }
        if (item.amount > 0) calcTotalIn += Number(item.amount);
        else calcTotalOut -= Number(item.amount);
      }

      // set history in order of last 7 days
      const days = [sun, mon, tue, wed, thu, fri, sat];
      const daysIn = [sunIn, monIn, tueIn, wedIn, thuIn, friIn, satIn];
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const historyData = [];
      for (let i = 0; i < 7; i++) {
        const day = new Date().getDay() - i;
        if (day < 0) {
          historyData.unshift({ day: daysOfWeek[day + 7], amountOut: days[day + 7], amountIn: daysIn[day + 7] });
        } else {
          historyData.unshift({ day: daysOfWeek[day], amountOut: days[day], amountIn: daysIn[day] });
        }
      }

      setHistory(historyData);
      console.log(historyData);
      setTotalIn(calcTotalIn);
      setTotalOut(calcTotalOut);

      // find largest day
      const maxDayOut = Math.max(...historyData.map(item => item.amountOut));
      setMaxOut(maxDayOut);
      const maxDayIn = Math.max(...historyData.map(item => item.amountIn));
      setMaxIn(maxDayIn);
    }

    // find recent activity
    if (localStorage.profile && localStorage.profile !== 'null') {
      const profile = JSON.parse(localStorage.profile);
      const recents = [];
      for (let i = 0; i < 5; i++) {
        if (profile.history[i]) recents.push(profile.history[i]);
      }
      setRecents(recents);
    }
  }

  useEffect(() => {
    // init welcome info
    welcomeInit();

    // listen for auth event
    window.addEventListener('auth', function () {
      if (sessionStorage.userSession || navigator.userAgent.toLowerCase().indexOf("pibrowser") >= 0) setAuthed(true);
      else setAuthed(false);
      welcomeInit();
    });

    // display new version info
    // if (!localStorage.version || Number(localStorage.version) < 220 && window.innerWidth < 900) {
    //   setNewUser(true);

    //   for (const item of document.querySelectorAll('.inactive')) {
    //     item.style.zIndex = '11';
    //   }

    //   function closeInfo() {
    //     localStorage.version = 220;
    //     setNewUser(false);
    //     for (const item of document.querySelectorAll('.inactive')) {
    //       item.style.zIndex = '0';
    //     }
    //     document.removeEventListener('click', closeInfo);
    //   }

    //   document.addEventListener('click', closeInfo);
    // }
  }, []);

  return (
    <>
      <div id='d' className='box active'>

        {authed ?
          <>
            <br />
            <h2 style={{ fontSize: '22px', marginBottom: '20px' }}>{t('Welcome_back')}, {localStorage.username}</h2>

            <div style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100% - 40px)', justifyContent: 'space-around' }}>

              {walletAddress &&
                <div className='section' style={{ marginBottom: '15px' }}>
                  <div className='border' style={{ padding: '10px' }}>
                    <span style={{ fontSize: '18px', display: 'flex', alignItems: 'baseline' }}>
                      <p>{t('Linked_wallet')}:</p>
                      {/* <p style={{ margin: '0 10px', fontSize: '25px' }}>{walletBalance} Pi</p> */}
                      <p style={{ margin: '0 10px', fontSize: '15px' }}>{walletAddress}</p>
                      {/* <a className='fas fa-refresh' style={{ fontSize: '12px', color: '#5b4afb' }} onClick={() => window.location.href = ''}></a> */}
                    </span><br />
                    {/* {balanceDelta > 0 && <i style={{ fontSize: '13px', color: 'green', opacity: '0.5' }}>+{balanceDelta.toFixed(3)} Pi since last login</i>}
                    {balanceDelta < 0 && <i style={{ fontSize: '13px', color: 'red', opacity: '0.5' }}>{balanceDelta.toFixed(3)} Pi since last login</i>}
                    {balanceDelta === 0 && <i style={{ fontSize: '13px', color: 'grey', opacity: '0.5' }}>{walletAddress}</i>} */}
                  </div>
                </div>
              }

              {balance > 0 ?
                <div className='section'>
                  <div className='border' style={{ padding: '10px' }}>
                    <span style={{ fontSize: '18px', display: 'flex' }}>
                      <p>{t('Pi_eCard_balance')}:</p>
                      <p style={{ margin: '0 10px', fontSize: '25px' }}>{balance} Pi</p>
                    </span>
                    {balanceDelta > 0 && <i style={{ fontSize: '13px', color: 'green', opacity: '0.5' }}>+{balanceDelta.toFixed(3)} Pi since last login</i>}
                    {balanceDelta < 0 && <i style={{ fontSize: '13px', color: 'red', opacity: '0.5' }}>{balanceDelta.toFixed(3)} Pi since last login</i>}
                    {balanceDelta === 0 && <i style={{ fontSize: '13px', color: 'grey', opacity: '0.5' }}>This is a legacy balance. All future transactions will be deducted from your Pi wallet.</i>}
                    <button id='withdrawBtn' onClick={props.withdraw}>Withdraw</button>
                  </div>
                </div>
                : <><br /><br /></>
              }

              <div className='section'>
                <h3>{t("This_Week's_Income")}</h3>

                <div className='border'>
                  <div id='graph' style={{ display: 'flex', justifyContent: 'space-evenly', margin: '10px 0' }}>
                    {history.map((item, index) => {
                      const barHeight = item.amountIn * (90 / maxIn);

                      return (
                        <div key={index}>
                          <i style={{ color: 'grey' }}>{item.amountIn.toFixed(2)}</i>
                          <div style={{ position: 'relative', height: '100px', margin: '10px 7.5px' }}>
                            <span style={{ position: 'absolute', bottom: '0', width: '15px', height: '100px', backgroundColor: '#e9e9e9', display: 'inline-block', borderRadius: '2px' }}></span>
                            <span style={{ position: 'absolute', bottom: '0', width: '15px', height: `${barHeight}px`, backgroundColor: '#5b4afb', display: 'inline-block', borderRadius: '2px' }}></span>
                          </div>
                          <p>{item.day.substring(0, 3)}</p>
                        </div>
                      );
                    })}
                  </div>

                  <br />
                  <b style={{ marginLeft: '10px' }}>{t('total')}: {totalIn.toFixed(3)} Pi</b>
                </div>
              </div>

              <div className='section'>
                <h3>{t("This_Week's_Spending")}</h3>

                <div className='border'>
                  <div id='graph' style={{ display: 'flex', justifyContent: 'space-evenly', margin: '10px 0' }}>
                    {history.map((item, index) => {
                      const barHeight = item.amountOut * (90 / maxOut);

                      return (
                        <div key={index}>
                          <i style={{ color: 'grey' }}>{item.amountOut.toFixed(2)}</i>
                          <div style={{ position: 'relative', height: '100px', margin: '10px 7.5px' }}>
                            <span style={{ position: 'absolute', bottom: '0', width: '15px', height: '100px', backgroundColor: '#e9e9e9', display: 'inline-block', borderRadius: '2px' }}></span>
                            <span style={{ position: 'absolute', bottom: '0', width: '15px', height: `${barHeight}px`, backgroundColor: '#5b4afb', display: 'inline-block', borderRadius: '2px' }}></span>
                          </div>
                          <p>{item.day.substring(0, 3)}</p>
                        </div>
                      );
                    })}
                  </div>

                  <br />
                  <b style={{ marginLeft: '10px' }}>{t('total')}: {totalOut.toFixed(3)} Pi</b>
                </div>
              </div>

              <div className='section'>
                <h3>{t("Recent_Activity")}</h3>

                <div className='border'>
                  {recents.map((item, index) => {
                    return (
                      <article key={index} className={`renderAmount ${item.amount >= 0 ? 'historyStyleGreen' : 'historyStyleRed'}`}>
                        <b>{item.amount} Pi {item.amount > 0 ? `${t('received_from')}` : `${t('sent_to')}`} {item.client}</b>
                        <p className='renderMemo'>Memo: {item.memo}</p>
                        <p className='renderDate'>{item.date.substring(0, 10)}</p>
                      </article>
                    );
                  })}
                  {recents.length === 0 && <i style={{ margin: '10px', display: 'inline-block' }}>{t('No_recent_activity')}</i>}
                </div>
              </div>

            </div>

          </> :
          <>
            <br />
            <h1 style={{ marginBottom: '20px', textAlign: 'center', width: '100%', lineHeight: '3rem', letterSpacing: '4px' }}>Transact Pi <br /> Smoothly & Securely</h1>
            <p style={{ fontSize: '18px', marginBottom: '20px', textAlign: 'center', width: '100%' }}>{t('Welcome_to_Pi_eCard_description')}</p>
            <br />
            <h3 style={{ margin: '0 10px', fontSize: '20px' }}>Login</h3>
            <LoginForm />
          </>
        }
      </div>

      {
        newUser &&
        <div id='welcomeNewUser'>
          <h3 style={{ color: 'white', marginBottom: '50px', opacity: '1' }}>{t('Welcome_to_Pi_eCard')} v2.1!</h3>
          <p style={{ opacity: '1' }}>{t('Clicking_the_above_boxes_will_open_all_your_transaction_and_account_features.')}</p>
          <p style={{ opacity: '1' }}>{t('Enjoy!')}</p>
          <br /><br />
          <img src={logoWhite} style={{ height: '50px', opacity: '1' }} alt='Pi eCard Logo' />
        </div>
      }
    </>
  );
}

export default Welcome;