import React from 'react';
import Nav, { DesktopNav } from '../components/Nav';
import Footer from '../components/Footer';
import '../styles/faqs.css';

function Question(props) {
  const textWithLineBreaks = props.a.split("<br />");

  return (
    <>
      <div className={`question ${props.i % 2 > 0 && 'question_odd'}`}>
        <h3>{props.i + 1}. {props.q}</h3>
        {textWithLineBreaks.map((line, index) => (
          <p className={index > 0 && 'question_indent'} key={index}>{line}<br /></p>
        ))}
      </div>
    </>
  )
}

const FAQs = () => {
  const questions = [
    {
      question: 'What is Pi eCard?',
      answer: 'Pi eCard is the easy POS (Point of Sale) system for the Pi Network, enabling in-person and online vendors and Pioneers to invoice others without the need to type in long public keys. The vendor and its customer can see the payment status at any stage.'
    },
    {
      question: 'What are the advantages of Pi eCard over Wallet.pi?',
      answer: 'There are various advantages if you choose Pi eCard for your transactions with Pi. Here are some of the best:<br /> ■ Pi eCard is easier and more secure to use than Wallet.pi because there is no risk of typing an invalid wallet address.<br /> ■ You can choose between different transaction methods such as QR code, username & URL.<br /> ■ In case you have any trouble with your Pi eCard, you can contact us by sending a message to support@piecard.app. We will get back to you ASAP to help resolve your issue.<br /> ■ Pi eCard is useful for vendors since it offers them quick, in-person transactions.'
    },
    {
      question: 'Can I order a physical card to spend Pi?',
      answer: 'We do not offer a physical Pi eCard. We are an on-chain service to make Pi transactions more convenient and believe that physical cards are now a thing of the past. Pi eCard is digital only.'
    },
    {
      question: "Why do I get a ‘This page could not be loaded’ error when visiting the Pi eCard site in the Pi Browser?",
      answer: "This is an error with the Pi Browser. Use ‘https://www.’ in front of the URL or use ‘piecard.app’."
    },
    {
      question: 'How do I change my Pi eCard password?',
      answer: "Use the ‘Add password’/’Change Password’ option in the menu and follow the steps on the screen."
    },
    {
      question: 'Is Pi eCard available on Mainnet?',
      answer: 'Yes! We are now processing transactions on the Mainnet. Pi eCard has reached 130K registered users. We are not available on Testnet anymore.'
    },
    {
      question: 'How can I change my linked wallet address?',
      answer: 'Please send an email to support@piecard.app to change your wallet address. Note that this is done manually in order to maintain security in your account. You can only use your own wallet address.'
    },
    {
      question: 'How can I install the Pi eCard app?',
      answer: "Go to piecard.app in Chrome or Safari and click the ‘add to home screen’ popup."
    },
    {
      question: 'How do I sign up with Pi eCard?',
      answer: "Registration is an automatic process in the Pi Browser. An account is created based on the user’s Pi Network account. It’s not possible to register outside the Pi Browser."
    },
    {
      question: 'Is KYC required to use the app?',
      answer: 'KYC and Mainnet Migration is required in order to connect your wallet and to make transactions via the app.'
    }
  ];

  return (
    <>
      {window.innerWidth < 901 ? <Nav /> : <><DesktopNav /><br /><br /><br /><br /><br /><br /></>}
      <div id='app' className='questions'>
        <h2>Frequently Asked Questions:</h2>
        {
          questions.map((question, index) => {
            return (
              <Question q={question.question} a={question.answer} i={index} />
            )
          })
        }
        <i style={{ display: 'block', margin: '20px' }}>
          If you still have a question unanswered, feel free to contact us at <a style={{ textDecoration: 'underline' }} href='mailto:support@piecard.app'>support@piecard.app</a>
        </i>
      </div>
      {window.innerWidth > 900 && <Footer />}
    </>
  )
};

export default FAQs;