import axios from 'axios';

const urlAPI = "https://server.piecard.app";
// const urlAPI = "http://localhost:5000";

/******************** Client Functions ********************/

async function findInvoice(id, setConfirmed, setAmount, setMemo, setRecipient, setDate, setReturnUrl, setSuccessUrl, setPaid, setBusinessName) {
  const response = await axios.get(`${urlAPI}/payment/${id}`);
  if (response.status === 200) {
    const payment = response.data.payment;
    console.log(payment);
    setAmount(payment.amount);
    setMemo(payment.memo);
    setRecipient(payment.payee);
    setDate(payment.date.substring(0, 10));
    setReturnUrl(payment.cancelURL);
    setSuccessUrl(payment.successURL);
    setPaid(payment.status);
    setBusinessName(payment.business_name);
    setConfirmed(true);
    sessionStorage.sandbox = payment.sandbox;
  }
}

async function requestPayment(id, amount, setBtnMessage, memo, recipient, setTxid, setConfirmed, setSuccess, setLoading, setSeconds, successUrl) {
  setBtnMessage("processing transfer, please wait...");

  if (sessionStorage.sandbox !== "true") {
    const payment = window.Pi.createPayment(
      {
        amount: Number(amount),
        memo: `Send ${amount} Pi for ${memo}`,
        metadata: { paymentType: "pi_ecard_api_payment" }
      },
      {
        onReadyForServerApproval: function (paymentId) {
          var data = {
            amount: Number(amount),
            paymentId,
            txid: "",
            type: 'API Payment',
            payee: recipient,
          };
          axios.post(`${urlAPI}/pi/approveUsername`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.userSession}`
            },
            withCredentials: true,
            credentials: "same-origin"
          });
        },
        onReadyForServerCompletion: async function (paymentId, txid) {
          if (setTxid && txid) setTxid(txid);
          const response = await payInvoice(id, amount, setBtnMessage, paymentId, txid);
          if (response) {
            setConfirmed(false);
            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
              setSeconds(2);
              setTimeout(() => {
                setSeconds(1);
                setTimeout(() => {
                  setSeconds(0);
                }, 1000);
              }, 1000);
            }, 1000);
          } else {
            setConfirmed(false);
            setSuccess(false);
            setLoading(false);
          }
        },
        onCancel: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlAPI}/pi/incomplete`, data);
        },
        onError: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlAPI}/pi/incomplete`, data);
        }
      }
    );
  } else {
    const response = await payInvoice(id, amount, setBtnMessage);
    if (response) {
      setConfirmed(false);
      setSuccess(true);
      setLoading(false);
      setTimeout(() => {
        setSeconds(2);
        setTimeout(() => {
          setSeconds(1);
          setTimeout(() => {
            setSeconds(0);
          }, 1000);
        }, 1000);
      }, 1000);
    } else {
      setConfirmed(false);
      setSuccess(false);
      setLoading(false);
    }
  }
}

async function payInvoice(id, amount, setBtnMessage, paymentId, txid) {
  setBtnMessage('finalising transfer, please do not close the app...');
  const data = {
    id,
    amount: Number(amount),
    paymentId,
    txid,
  };
  const response = await axios.post(`${urlAPI}/api/pay`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.userSession}`
    }
  }).catch((error) => {
    console.log(error);
    setBtnMessage('UNKNOWN ERROR please contact support@piecard.app');
    return false;
  });
  if (response.status === 200) {
    setTimeout(() => {
      window.open(response.data.url, "_self");
    }, 3000);
    return true;
  }
  else {
    setBtnMessage('UNKNOWN ERROR please contact support@piecard.app');
    return false;
  }
}

export {
  findInvoice,
  requestPayment,
  payInvoice
}