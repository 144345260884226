import axios from 'axios';

/******************** Variables ********************/

let flashMessage = "";
let flashBool = false;
const flashTime = 3000;
const authToken = localStorage.getItem("userSession");
const urlAPI = "https://server.piecard.app";
// const urlAPI = "http://localhost:5001";
const instance = axios.create({
  baseURL: urlAPI,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${authToken}`
  },
  withCredentials: true,
  credentials: "same-origin"
});


/******************** User Functions ********************/

// reusable function to check authed
async function checkAuthed() {
  const authTkn = sessionStorage.userSession;
  console.log(authTkn);
  if (!authTkn) {
    const username = prompt("Please enter your username", "");
    const password = prompt("Please enter your password", "");
    const response = await appLogin(username, password);
    console.log(response);
  } else return true;
}

async function piLogin(auth) {
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const referrer = urlParams.get("r");
    if (referrer) {
      const config = {
        username: localStorage.username,
        uid: localStorage.uid,
        referral: referrer,
        auth
      };
      const response = await axios.post(`${urlAPI}/register/referred`, config);
      if (response.status === 200 || response.status === 201 || response.status === 202) {
        const token = response.data.token;
        sessionStorage.removeItem("userSession");
        localStorage.removeItem("userSession");
        sessionStorage.setItem("userSession", token);
        localStorage.setItem("userSession", token);
        window.dispatchEvent(new Event("storage"));
        myProfile();
      }
      if (response.status === 201) {
        alert("Welcome to Pi eCard!");
      }
    } else {
      const config = { username: localStorage.username, uid: localStorage.uid, auth };
      const response = await axios.post(`${urlAPI}/register/`, config).catch(() => alert('Auth failed, please try again'));
      if (response.status === 200 || response.status === 201 || response.status === 202) {
        const token = response.data.token;
        sessionStorage.removeItem("userSession");
        localStorage.removeItem("userSession");
        sessionStorage.setItem("userSession", token);
        localStorage.setItem("userSession", token);
        window.dispatchEvent(new Event("storage"));
        myProfile();
      }
      if (response.status === 201) {
        alert("Welcome to Pi eCard!");
      }
      if (response.status === 202) {
        alert("You have pending inbound transactions. These will be processed shortly.");
      }
    }
  } catch (error) {
    console.log(error);
  }
}

async function appLogin(username, password, close) {
  try {

    const config = { username: username, password: password };
    const response = await axios.post(`${urlAPI}/register/app`, config);

    if (response.status === 200) {
      const token = response.data.token;
      sessionStorage.removeItem("userSession");
      localStorage.removeItem("userSession");
      sessionStorage.setItem("userSession", token);
      localStorage.setItem("userSession", token);
      localStorage.setItem("username", username);
      localStorage.signed = "true";
      window.dispatchEvent(new Event("auth"));
      myProfile();
      if (close) close();
    }

  } catch (error) {
    console.log(error);
    alert('Invalid username or password');
  }
}

// Display profile names
async function myProfile(setAddPassword) {
  const authToken = localStorage.getItem("userSession");
  await axios
    .get(`${urlAPI}/register`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: true,
      credentials: "same-origin"
    })
    .then((response) => {
      if (response.status === 200) {
        const profile = response.data.user;
        localStorage.profile = JSON.stringify(profile);
        localStorage.balance = response.data.user.balance.toFixed(7);
        if (profile.wallet_address) localStorage.walletBalance = Number(response.data.wallet_balance).toFixed(3);
        const history = response.data.user.history;
        localStorage.history = JSON.stringify(history);
        window.dispatchEvent(new Event("storage"));
        window.dispatchEvent(new Event("auth"));
        if (!profile.password) localStorage.password = "false";
        else localStorage.password = "true";
        if (!profile.wallet_address) localStorage.wallet = "false";
        else localStorage.wallet = "true";
        if (setAddPassword && (!profile.password || !profile.wallet_address || profile.balance > 0.001) /*&& (!localStorage.version || Number(localStorage.version) < 220 )*/)
          setAddPassword(true);
        else if (setAddPassword && !profile.wallet_address)
          setAddPassword(true);
        return;
      }
    })
    .catch((error) => {
      console.log(error);
      localStorage.signed = "false";
      localStorage.profile = null;
      localStorage.balance = null;
      localStorage.history = null;
      window.dispatchEvent(new Event("storage"));
      return;
    });
}

// Get overflow history
async function getMoreHistory(index) {
  const authToken = localStorage.getItem("userSession");
  const response = await axios
    .get(`${urlAPI}/register/history/${index}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: true,
      credentials: "same-origin"
    }).catch((error) => {
      console.log(error);
      return [];
    });

  return response.data.history;
}

// Log out all other sessions except current session
async function logout() {
  const authToken = localStorage.getItem("userSession");
  try {
    if (authToken == null) {
      flashMessage = "No user is authenticated, please login!!!";
    } else {
      const username = localStorage.username;
      const data = {
        username
      };
      const response = await axios.post(`${urlAPI}/logout/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`
        },
        withCredentials: true,
        credentials: "same-origin"
      });
      if (response.status === 200) {
        flashMessage = `Successfully logged out from all devices!!!`;
        sessionStorage.removeItem("userSession");
        localStorage.removeItem("userSession");
        localStorage.username = null;
        localStorage.balance = null;
        localStorage.signed = "false";
        localStorage.history = null;
        window.dispatchEvent(new Event("storage"));
        window.dispatchEvent(new Event("auth"));
      }
    }
  } catch (error) {
    console.log(error);
    localStorage.clear();
    sessionStorage.clear();
    const errorMessage = error;
    if (errorMessage.length > 0) flashMessage = errorMessage;
  }
  flashBool = true;

  // Flash message
  try {
    if (flashBool && flashMessage.length > 0) {
      const pTag = document.createElement("p");
      pTag.textContent = flashMessage;
      document.querySelector("#login_log").appendChild(pTag);
      setTimeout(() => {
        flashMessage = "";
        flashBool = false;
        document.querySelector("#login_log").removeChild(pTag);
      }, flashTime);
    }
  } catch (error) {
    console.log(error);
  }
}

async function addPassword(password) {
  checkAuthed();
  if (sessionStorage.userSession) {
    console.log(password)
    if (!password) password = prompt(
      "Please create a password to login outside the Pi Browser:",
      ""
    );
    const config = {
      username: localStorage.username,
      uid: localStorage.uid,
      password
    };
    const authToken = sessionStorage.userSession;
    const response = await axios.post(`${urlAPI}/register/manual`, config, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: true,
      credentials: "same-origin"
    }).catch((error) => console.log(error));
    if (response.status == 200 || response.status == 201) {
      localStorage.password = "true";
      window.dispatchEvent(new Event("auth"));
      alert(`Success! You can now login to our mobile or desktop app.`);
    } else {
      alert(response.data.message);
    }
  } else openBrowser();
}

async function addWalletAddress(wallet) {
  checkAuthed();
  const authToken = localStorage.getItem("userSession");
  const response = await axios.post(`${urlAPI}/register/wallet`, { wallet_address: wallet }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    withCredentials: true,
    credentials: "same-origin"
  }).catch((error) => {
    console.log(error)
    return false;
  });

  if (response.status == 200) return true;
}

/******************** Invoice Functions ********************/

async function createInvoice(amount, memo) {
  checkAuthed();
  const authTkn = localStorage.getItem("userSession");
  const data = {
    amount: Number(amount),
    memo,
    user: localStorage.username
  };
  const response = await axios.post(`${urlAPI}/payment/create`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authTkn}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  if (response.status === 200) {
    return response.data;
  } else alert("Unknown error. Please try again later.");
}

async function getInvoiceStatus(setStatus, setSuccess) {
  const id = localStorage.invoiceID;
  const response = await axios.get(`${urlAPI}/payment/${id}`);
  if (response.status == 200 && response.data.payment.paid == true) {
    setStatus(`Pi received!`);
    setSuccess(true);
    myProfile();
  } else {
    console.log(response);
    setStatus(`waiting for transfer...`);
    const retry = setTimeout(() => getInvoiceStatus(setStatus, setSuccess), 4000);
  }
}

async function createURLInvoice(amount, memo) {
  checkAuthed();
  const data = {
    amount: Number(amount),
    memo,
    user: localStorage.username
  };
  const authToken = sessionStorage.userSession;
  const response = await axios.post(`${urlAPI}/payment/create`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  if (response.status === 200) {
    const url = "https://piecard.app/invoice/" + response.data.id;
    return url;
  } else alert("Unknown error. Please try again later.");
}

/******************** Pay Functions ********************/

async function payScanned(id, setStatus, setSuccess, setScanned, setConfirmMessage) {
  checkAuthed();
  const response = await axios.get(`${urlAPI}/payment/${id}`)
    .catch(error => {
      console.log(error);
      setStatus("Unexpected error finding transaction. Please check QR code and try again.");
      setSuccess(false);
    });
  if (
    response.status === 200 &&
    response.data.payment.payee !== localStorage.username
  ) {
    const payment = response.data.payment;
    setScanned(true);
    setConfirmMessage(`Pay ${payment.amount}Pi to ${payment.payee}?`);
    localStorage.paymentID = payment._id;
    localStorage.amount = payment.amount;
    localStorage.payee = payment.payee;
    localStorage.memo = payment.memo;
  } else if (response.data.payment.payee == localStorage.username) {
    setStatus("cannot transfer to yourself");
    setSuccess(false);
  }
}

async function payInvoice(id, setSuccess, setStatus, setConfirmed, setAmount, setMemo, setRecipient, setDate) {
  const response = await axios.get(`${urlAPI}/payment/${id}`);
  if (response.status === 200 && response.data.payment.payee !== localStorage.username) {
    const payment = response.data.payment;
    setAmount(payment.amount);
    setMemo(payment.memo);
    setRecipient(payment.payee);
    setDate(payment.date.substring(0, 10));
    localStorage.paymentID = payment._id;
    localStorage.amount = payment.amount;
    localStorage.payee = payment.payee;
    localStorage.memo = payment.memo;
    setStatus('Pending confirmation...');
    setConfirmed(false);
  } else if (response.data.payment.payee == localStorage.username) {
    const payment = response.data.payment;
    setAmount(payment.amount);
    setMemo(payment.memo);
    setRecipient(payment.payee);
    setDate(payment.date.substring(0, 10));
    setStatus("cannot transfer to yourself");
    setSuccess(false);
  }
}

async function checkUserWallet(username, setConfirmed, setStatus, setSuccess) {
  checkAuthed();
  const authToken = sessionStorage.userSession;
  const response = await axios.post(`${urlAPI}/pi/wallet`, { username }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  if (response.status === 200) {
    if (response.data.success == true) {
      setConfirmed(false);
    } else {
      setStatus(response.data.message);
      setSuccess(false);
      setConfirmed(true);
    }
  } else alert("Unknown error. Please try again later.");
}

async function checkWalletAddress(wallet, setConfirmed, setStatus, setSuccess) {
  checkAuthed();
  const authToken = sessionStorage.userSession;
  const response = await axios.post(`${urlAPI}/pi/checkWallet`, { wallet }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`
    },
    withCredentials: true,
    credentials: "same-origin"
  });
  if (response.status === 200) {
    if (response.data.success == true) {
      setConfirmed(false);
    } else {
      setStatus(response.data.message);
      setSuccess(false);
      setConfirmed(true);
    }
  } else alert("Unknown error. Please try again later.");
}

/******************** Account Functions ********************/

async function getInvoices() {
  checkAuthed();
  const response = await instance.get("/payment", {
    params: {
      username: localStorage.username
    }
  });
  console.log(response);
  if (response.status == 200) {
    const invoices = response.data.invoices;
    return invoices;
  } else return [];
}

/******************** Other Functions ********************/

function openBrowser() {
  window.open("pi://www.piecard.app");
}

export {
  checkAuthed,
  piLogin,
  appLogin,
  myProfile,
  getMoreHistory,
  logout,
  payScanned,
  payInvoice,
  checkUserWallet,
  checkWalletAddress,
  createInvoice,
  getInvoiceStatus,
  createURLInvoice,
  openBrowser,
  getInvoices,
  addPassword,
  addWalletAddress
};