import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { getMoreHistory } from '../app/routes';

function Transaction(props) {
  const { t } = useTranslation();

  return (
    <article className={`renderAmount ${props.data.amount >= 0 ? 'historyStyleGreen' : 'historyStyleRed'}`} key={props.data._id}>
      <b>{props.data.amount} Pi {props.data.amount >= 0 ? `${t('received_from')}` : `${t('sent_to')}`} {props.data.client}</b>
      <p className='renderMemo'>{t('Memo')}: {props.data.memo}</p>
      <p className='renderDate'>{props.data.date.substring(0, 10)}</p>
    </article>
  );
}

function History(props) {
  const { t } = useTranslation();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [index, setIndex] = useState(0);

  const updateHistory = () => {
    if (localStorage.getItem('history') !== (null || 'null' || undefined) && localStorage.history) {
      console.log('Updating history...');
      const storedHistory = JSON.parse(localStorage.getItem('history'));
      console.log(storedHistory);
      setHistory(storedHistory);
      setLoading(false);
    } else {
      setHistory([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (localStorage.getItem('history') !== null) updateHistory();
    window.addEventListener('storage', updateHistory);
  }, []);

  const seeMore = async () => {
    const foundHistory = await getMoreHistory(index);
    if (foundHistory.length > 0) {
      for (const item of foundHistory) {
        history.push(item);
      }
      setHistory(history);
      setIndex(index + foundHistory.length);
    } else alert(t('no_more_transactions_to_show'));
  }

  return (
    <div>
      <h3 style={{ marginBottom: '10px' }}>{t('History')}</h3>
      <div id="historySection">
        {history.map((transaction, index) => {
          return <Transaction data={transaction} key={index} />
        })}
      </div>
      {history.length >= 50 && <a style={{ color: '#5b4afb', cursor: 'pointer' }} onClick={seeMore}>{t('see_more')}</a>}
      {history.length === 0 && loading === false && sessionStorage.userSession && <p>{t('your_transactions_will_appear_here')}...</p>}
      {!sessionStorage.userSession && <p>{t('please_login_to_see_your_transactions')}</p>}
      {(loading && sessionStorage.userSession) && <><div id="loadingHistoryWheel" className="loader"></div><br /></>}
    </div>
  );
}

export default History;