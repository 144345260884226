import React from 'react';
import { useTranslation } from 'react-i18next';
import Failed from '../../assets/failed.png';

function BuyPi(props) {
  const { t } = useTranslation();

  return (
    <>
      <div className='modalContainer'>
        <div className='payModal' style={{ height: '400px' }}>
          <header className="modal-header">
            <h3>{t('Top_Up')}
              <button className="close-modal"
                style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
                onClick={() => props.close()}>✕</button>
            </h3>
          </header>
          <section className="modal-content">
            <br /><br />
            {props.success === false &&
              <div className="paymentSuccessful" id="topupUnsuccessful">
                <h3>{t('Payment_Unsuccessful')}</h3>
                <br />
                <img className="completeImg" src={Failed}></img>
              </div>
            }
            {props.success === true &&
              <a id="topupSuccessful">{t('Buy_Pi_Message')}</a>
            }
            {props.success == null && <><div id="topupLoader" className="loader"></div><br /></>}
            <a id="topupMessage">{props.status}</a>
          </section>
        </div>
      </div>

      <div id="darkTint"></div>
    </>
  );
}

export default BuyPi;