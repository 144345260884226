import React from 'react';
import i18n from "i18next";

function Translate(props) {
  const languages = [
    { code: 'en', name: 'English', country_code: 'gb' },
    { code: 'ar', name: 'العربية', country_code: 'sa' },
    { code: 'bn', name: 'বাংলা', country_code: 'bd' },
    { code: 'es', name: 'Español', country_code: 'es' },
    { code: 'fil', name: 'Filipino', country_code: 'ph' },
    { code: 'fr', name: 'Français', country_code: 'fr' },
    { code: 'ha', name: 'Hausa', country_code: 'ng' },
    { code: 'hi', name: 'हिन्दी', country_code: 'in' },
    { code: 'id', name: 'Bahasa Indonesia', country_code: 'id' },
    { code: 'kr', name: '한국어', country_code: 'kr' },
    { code: 'pt', name: 'Português', country_code: 'pt' },
    { code: 'vi', name: 'Tiếng Việt', country_code: 'vn' },
    { code: 'zh', name: '中文', country_code: 'cn' }
  ];

  return (
    <>
      <div id='translate' >
        {languages.map((lang) => (
          <>
            <a
              key={lang.code}
              onClick={() => { i18n.changeLanguage(lang.code); props.close() }}
            >
              <img
                src={`/assets/flags/${lang.country_code}.svg`}
                alt="flag"
              />
              <span>{lang.name}</span>
            </a>
            <br />
          </>
        ))}
      </div>
      <div id="darkTint" onClick={props.close}></div>
    </>
  );
}

export default Translate;