import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function InvoiceURL(props) {
  const { t } = useTranslation();
  const [url, setUrl] = useState(t('generating_url...'));

  useEffect(() => {
    if (props.url) setUrl(props.url);
  }, [props.url]);

  const handleCopy = () => {
    navigator.clipboard.writeText(props.url)
      .then(() => {
        alert(t('copied'));
      })
      .catch(() => {
        alert("something went wrong");
      });
  }

  return (
    <>
      <div className="modalContainer">
        <div className="payModal" style={{ height: '300px' }}>
          <header className="modal-header">
            <h3>{t('Invoice')}
              <button className="close-modal"
                style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
                onClick={() => props.setUrlModal(false)}>✕</button>
            </h3>
          </header>
          <section className="modal-content">
            <br /><br />
            <div style={{ border: 'solid 1px #030303', borderRadius: '3px', padding: '3px', width: '100%' }}>
              <a style={{ width: 'calc(100% - 40px)', overflowX: 'auto', display: 'inline-block' }}>{url}</a>
              <i className="fas fa-copy" style={{ float: 'right', margin: '3px 10px' }} onClick={handleCopy}></i>
            </div><br /><br /><br />
            <a>{t('Click')} <span onClick={() => props.openInvoices()} style={{ color: '#5b4afb' }}>{t('here')} </span>
              {t('to_see_the_progress_of_your_outstanding_invoices')}</a>
          </section>
        </div>
      </div>

      <div id='darkTint'></div>
    </>
  );
}

export default InvoiceURL;