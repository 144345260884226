import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getInvoiceStatus } from '../../app/routes.js';
import Complete from '../../assets/complete.png';
import Failed from '../../assets/failed.png';

function InvoiceQR(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(t('transfer_pending...'));
  const [success, setSuccess] = useState(null);

  const createQR = async () => {
    await new window.QRCode(document.getElementById("qrcode"), {
      text: props.data.id,
      width: 270,
      height: 270,
      colorDark: "#000",
      colorLight: "#ffffff",
      correctLevel: window.QRCode.CorrectLevel.H
    });
    document.getElementById("qrcode").style.padding = "25px 5px";
    document.getElementById("qrcode").style.background = "#fff";
    document.getElementById("qrcode").style.margin = "auto";
  }

  useEffect(() => {
    createQR();
    localStorage.invoiceID = props.data.id;
    getInvoiceStatus(setStatus, setSuccess);
  }, []);

  return (
    <>
      <div className="modalContainer">
        <div className="payModal">
          <div className="modal-header">
            <h3>{t('Transfer')}</h3>
            <button className="close-modal"
              style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-30px' }}
              onClick={() => props.setQrModal(false)}>✕</button>
          </div>
          <section className="modal-content">
            {success === true &&
              <div className="paymentSuccessful" id="invoiceSuccessful">
                <br />
                <h3>{t('Transfer_Successful')}!</h3>
                <img class="completeImg" src={Complete} />
              </div>
            }
            {success === false &&
              <div className="paymentSuccessful" id="invoiceUnsuccessful">
                <br />
                <h3>{t('Transfer_Unsuccessful')}</h3>
                <img className="completeImg" src={Failed} />
              </div>
            }
            {success === null && <><div id="qrcode"></div><br /></>}
            <a>ID: <span>{props.data.id}</span></a><br /><br />
            <a className="status">{t('Status')}: {status}</a><br /><br />
            {success === null && <><div id="loaderOne" class="loader"></div><br /></>}
          </section>
        </div>
      </div>

      <div onClick={() => props.setQrModal(false)} id="darkTint"></div>
    </>
  );
}

export default InvoiceQR;