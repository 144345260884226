import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { appLogin, openBrowser } from '../app/routes.js';

export function LoginForm() {
  const { t } = useTranslation();
  const [loginStatus, setLoginStatus] = useState('Submit');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const nullFunction = () => { }

  const handleLogin = async () => {
    setLoginStatus('Loading...');
    if (username === '' || password === '') {
      alert('Please enter a username and password');
      setLoginStatus('Login');
    } else {
      await appLogin(username, password, nullFunction);
      window.dispatchEvent(new Event('auth'));
    }
  }

  return (

    <div id="login_form" className='border'>
      <input id="username_input" placeholder={t('Username')} type="text" onChange={(e) => setUsername(e.target.value)} />
      <input id="password_input" placeholder={t('Password')} type="password" onChange={(e) => setPassword(e.target.value)} />
      <button id="loginBtn" onClick={handleLogin}>{loginStatus}</button>
      <br /><br />
      <i>{t("Don't_have_an_account?_Please_use_the")} <a style={{ textDecoration: 'underline' }} onClick={openBrowser}>Pi Browser</a> {t('to_register')}.</i>
    </div>
  );
}

function Login(props) {
  const { t } = useTranslation();
  const [loginStatus, setLoginStatus] = useState('Login');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    setLoginStatus('Loading...');
    if (username === '' || password === '') {
      alert('Please enter a username and password');
      setLoginStatus('Login');
    } else {
      await appLogin(username, password, props.close);
      window.dispatchEvent(new Event('auth'));
    }
  }

  return (
    <div className='modalContainer'>
      <div className='payModal' style={{ maxHeight: '400px' }}>
        <header className="modal-header" id="invoicesModalHeader">
          <h3>{t('Login')}
            <button className="close-modal"
              style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
              onClick={() => props.close()}>✕</button>
          </h3>
        </header>
        <section className="modal-content login-modal-content">
          <br /><br />
          <div id="login_form">
            <input id="username_input" placeholder={t('Username')} type="text" onChange={(e) => setUsername(e.target.value)} />
            <input id="password_input" placeholder={t('Password')} type="password" onChange={(e) => setPassword(e.target.value)} />
            <button id="loginBtn" onClick={handleLogin}>{loginStatus}</button>
            <br /><br /><br />
            <i>{t('Please_use_the')} <a style={{ textDecoration: 'underline' }} onClick={openBrowser}>Pi Browser</a> {t('to_register')}</i>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Login;