import axios from 'axios';
import { piLogin, myProfile } from "./routes.js";

const urlApi = "https://server.piecard.app";

async function auth() {
  const scopes = ["username", "payments", "wallet_address"];
  function onIncompletePaymentFound(payment) {
    var data = {
      paymentId: payment.identifier,
      txid: payment.transaction.txid
    };
    axios.post(`${urlApi}/pi/incomplete`, data);
  }
  try {
    window.Pi.authenticate(scopes, onIncompletePaymentFound).then(async function (authRes) {
      const uid = authRes.user.uid;
      const username = authRes.user.username;
      localStorage.username = username;
      localStorage.uid = uid;
      piLogin(authRes);
      console.log("Pi Browser authenticated", authRes);
    }).catch(err => {
      console.log(err);
      if (err?.message) alert(err.message);
    });
  } catch (err) {
    console.log(err);
    if (err?.message) alert(err.message);
  }
}

auth();

async function createPayment(setConfirmed, setStatus, setSuccess, username, password) {
  let authToken = localStorage.getItem("userSession")

  if (username && password) {
    const config = { username: username, password: password };
    const response = await axios.post(`${urlApi}/register/app`, config).catch(() => {
      alert('invalid username or password');
    });

    if (response.status === 200) {
      const token = response.data.token;
      sessionStorage.removeItem("userSession");
      localStorage.removeItem("userSession");
      sessionStorage.setItem("userSession", token);
      localStorage.setItem("userSession", token);
      localStorage.setItem("username", username);
      authToken = token;
      localStorage.signed = "true";
      await myProfile();
    } else if (response.status == 201) alert('Please go to piecard.app in the Pi Browser to activate your account.');
    else return;
  }

  setConfirmed(true);
  setStatus("processing transfer, please wait...");
  const payment = window.Pi.createPayment(
    {
      amount: localStorage.amount * 1.01, // amount plus 1% fee
      memo: `Transfer ${localStorage.amount} Pi to ${localStorage.payee}`,
      metadata: { paymentType: "pi_ecard_qr_payment" }
    },
    {
      onReadyForServerApproval: function (paymentId) {
        var data = {
          amount: Number(localStorage.amount * 1.01),
          paymentId: paymentId,
          id: localStorage.paymentID,
          payee: localStorage.payee,
          txid: ""
        };
        axios.post(`${urlApi}/pi/approve`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true,
          credentials: "same-origin"
        });
      },
      onReadyForServerCompletion: async function (paymentId, txid) {
        var data = {
          paymentId: paymentId,
          txid: txid,
          id: localStorage.paymentID,
          amount: Number(localStorage.amount),
          username: localStorage.username,
          payee: localStorage.payee,
          memo: localStorage.memo
        };
        const response = await axios.post(`${urlApi}/pi/complete`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
          },
          withCredentials: true,
          credentials: "same-origin"
        });
        // Payment success
        if (response.status == 200 || response.status == 202) {
          setSuccess(true);
          setStatus("transfer successful!");
        } else {
          setSuccess(false);
          setStatus(response.data.message);
        }
      },
      onCancel: function (paymentId, txid) {
        var data = {
          paymentId: paymentId,
          txid: txid
        };
        axios.post(`${urlApi}/pi/incomplete`, data);
      },
      onError: function (paymentId, txid) {
        var data = {
          paymentId: paymentId,
          txid: txid
        };
        axios.post(`${urlApi}/pi/incomplete`, data);
      }
    }
  );
}

async function payByUsername(amount, payee, memo, setStatus, setSuccess) {
  setStatus("processing transfer...");
  const authToken = localStorage.getItem("userSession");
  if (amount > 0 && payee && payee !== localStorage.username) {
    const payment = window.Pi.createPayment(
      {
        amount: Number(amount * 1.01), // 1% fee
        memo: `Add ${amount} Pi to account`,
        metadata: { paymentType: "pi_ecard_username_payment" }
      },
      {
        onReadyForServerApproval: function (paymentId) {
          var data = {
            amount: Number(amount),
            paymentId: paymentId,
            payee,
            txid: ""
          };
          axios.post(`${urlApi}/pi/approveUsername`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`
            },
            withCredentials: true,
            credentials: "same-origin"
          });
        },
        onReadyForServerCompletion: async function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid,
            amount: Number(amount),
            username: localStorage.username,
            payee: payee,
            memo: memo
          };
          const response = await axios.post(
            `${urlApi}/pi/payUsername`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
              },
              withCredentials: true,
              credentials: "same-origin"
            }
          );
          // Payment success
          if (response.status == 202) {
            setSuccess(true);
            setStatus("transfer successful!");
          } else {
            setSuccess(false);
            setStatus("tranfer failed");
          }
          myProfile();
        },
        onCancel: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlApi}/pi/incomplete`, data);
        },
        onError: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlApi}/pi/incomplete`, data);
        }
      }
    );
  } else if (payee == localStorage.username) {
    setSuccess(false);
    setStatus("cannot transfer to yourself");
  } else {
    setSuccess(false);
    setStatus("amount must be greater than zero");
  }
}

async function payByWallet(amount, wallet, memo, setStatus, setSuccess) {
  setStatus("processing transfer...");
  const authToken = localStorage.getItem("userSession");
  if (amount > 0 && wallet) {
    const payment = window.Pi.createPayment(
      {
        amount: Number(amount * 1.01), // 1% fee
        memo: `Add ${amount} Pi to account`,
        metadata: { paymentType: "pi_ecard_username_payment" }
      },
      {
        onReadyForServerApproval: function (paymentId) {
          var data = {
            amount: Number(amount),
            paymentId,
            wallet,
            txid: ""
          };
          axios.post(`${urlApi}/pi/approveWallet`, data, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`
            },
            withCredentials: true,
            credentials: "same-origin"
          });
        },
        onReadyForServerCompletion: async function (paymentId, txid) {
          var data = {
            paymentId,
            txid,
            amount: Number(amount),
            wallet,
            memo
          };
          const response = await axios.post(
            `${urlApi}/pi/payWallet`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
              },
              withCredentials: true,
              credentials: "same-origin"
            }
          );
          // Payment success
          if (response.status == 202) {
            setSuccess(true);
            setStatus("transfer successful!");
          } else {
            setSuccess(false);
            setStatus("tranfer failed");
          }
          myProfile();
        },
        onCancel: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlApi}/pi/incomplete`, data);
        },
        onError: function (paymentId, txid) {
          var data = {
            paymentId: paymentId,
            txid: txid
          };
          axios.post(`${urlApi}/pi/incomplete`, data);
        }
      }
    );
  } else {
    setSuccess(false);
    setStatus("amount must be greater than zero");
  }
}

async function withdraw(amount, wallet) {
  let profile, wallet_address;
  const authToken = localStorage.getItem("userSession");
  if (!wallet) {
    profile = JSON.parse(localStorage.profile);
    wallet_address = profile.wallet_address;
    if (amount > Number(profile.balance).toFixed(7)) {
      alert('Please enter a valid amounts');
      return false;
    }
    wallet_address = await prompt("Please enter or paste your KYC'd Pi Mainnet Wallet Address (Public Key)");
  } else if (wallet) wallet_address = wallet;
  else return false;

  if (!wallet_address) {
    wallet_address = prompt("Please enter or paste your Pi Mainnet Wallet Address (Public Key)");
    const confirmedAddress = window.confirm(
      `Please confirm your Pi Wallet Address ${wallet_address} (this cannot be undone once confirmed!)`
    );
    if (!confirmedAddress) return false;
  } else {
    const confirmedAddress = window.confirm(
      `Confirm withdrawal of ${amount} Pi to ${wallet_address}`
    );
    if (!confirmedAddress) return false;
  }

  if (amount < 0 || !wallet_address) {
    alert('Please enter a valid amount and/or Pi Wallet Address');
    return false;
  }

  const response = await axios.post(
    `${urlApi}/pi/withdraw`,
    {
      amount: Number(amount),
      wallet_address,
      uid: localStorage.uid
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`
      },
      withCredentials: true,
      credentials: "same-origin"
    }
  ).catch(err => {
    console.log(err);
    return alert(err?.response?.data?.message || "Something went wrong. Please try again later.")
  });

  if (response && response.status == 200) {
    myProfile();
    alert("Withdrawal successful! Please check your Pi Wallet. You may contact support@piecard.app if you have any questions.");
    return true;
  } else return false;
}

export {
  auth,
  createPayment,
  payByUsername,
  payByWallet,
  withdraw
};