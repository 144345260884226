import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, useParams, useLocation } from 'react-router-dom';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import Layout from "./pages/Layout.js";
import Home from "./pages/Home.js";
import Invoice from "./pages/Invoice.js";
import Pay from "./pages/Pay.js";
import FAQs from './pages/FAQs.js';
import NoPage from "./pages/NoPage.js"; 
// CSS:
import './styles/styles.css';
import './styles/fonts/all.min.css';
// Scripts
import './app/routes.js';
import './app/payment.js';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'ar', 'bn', 'es', 'fil', 'fr', 'ha', 'hi', 'id', 'kr', 'pt', 'vi', 'zh'],
    fallbackLng: "en",
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}.json'
    },
    react: { useSuspense: false }
  });

function ShowInvoice() {
  let { paymentId } = useParams();
  return <Invoice paymentId={paymentId} />;
}

function ShowPay() {
  let { paymentId } = useParams();
  let query = new URLSearchParams(useLocation().search);
  let redirect = query.get('redirect');
  if (redirect && redirect == 'true') window.open(`pi://piecard.app/pay/${paymentId}`);
  return <Pay paymentId={paymentId} />;
}

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route exact path="/invoice/:paymentId" element={<ShowInvoice />} />
          <Route exact path="/pay/:paymentId" element={<ShowPay />} />
          <Route exact path="/faqs" element={<FAQs />} />
          <Route exact path="/*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  ); 
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);