import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import '../styles/pay.css';
import Logo from '../assets/logoWhite.png';
import Complete from '../assets/complete.png';
import Failed from '../assets/failed.png';
import { findInvoice, requestPayment } from '../app/api.js';

function Pay(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState('loading...');
  const [recipient, setRecipient] = useState('loading...');
  const [businessName, setBusinessName] = useState(null);
  const [amount, setAmount] = useState('0');
  const [memo, setMemo] = useState('Loading...');
  const [returnUrl, setReturnUrl] = useState('');
  const [successUrl, setSuccessUrl] = useState('');
  const [txid, setTxid] = useState('n/a');
  const [confirmed, setConfirmed] = useState(false);
  const [success, setSuccess] = useState(null);
  const [btnMessage, setBtnMessage] = useState('');
  const [sandbox, setSandbox] = useState(false);
  const [seconds, setSeconds] = useState(3);
  const [isPiBrowser, setIsPiBrowser] = useState(true);
  const [paid, setPaid] = useState(false);

  const getInfo = async () => {
    if (!props.paymentId) window.location.href = '/';  
    // if (navigator.userAgent.toLowerCase().indexOf("pibrowser") < 0) window.open(`pi://piecard.app/pay/${props.paymentId}`);
    await findInvoice(props.paymentId, setConfirmed, setAmount, setMemo, setRecipient, setDate, setReturnUrl, setSuccessUrl, setPaid, setBusinessName);
    if (sessionStorage.sandbox == 'true') setSandbox(true);
  };

  useEffect(() => {
    getInfo();
    setTimeout(() => {
      // if (navigator.userAgent.toLowerCase().indexOf("pibrowser") < 0) {
      //   setConfirmed(false);
      //   setSuccess(null);
      //   setIsPiBrowser(false);
      // }
    }, 1000);
  }, []);

  const handlePay = async () => {
    if (!localStorage.getItem('username') || !localStorage.getItem('userSession')) return alert('Please wait a second, while we log you in...');
    setBtnMessage('Loading...');
    setLoading(true);
    await requestPayment(props.paymentId, amount, setBtnMessage, memo, recipient, setTxid, setConfirmed, setSuccess, setLoading, setSeconds, successUrl);
  };

  return (
    <div id="pay">
      {
        confirmed &&
        <>
          <h1 id='amount'>{amount} Pi</h1>

          <h3 id='memo'>{memo}</h3>

          <span id='details'>
            <p>{t('Invoice_ID')}: {props.paymentId}</p>
            <br />
            <p style={{ float: 'right' }}>{date}</p>
            <p>{t('Billed_to')}: {businessName || recipient}</p>
            <br />
            {sandbox && <span id='sandbox'>{t('Sandbox_Mode')}</span>}
          </span>

          <i>{btnMessage}</i>
          {
            (loading == false && !paid) &&
              <span id='buttons'>
                <button id='payBtn' onClick={handlePay}>
                  <img id='btnLogo' src={Logo}></img>
                  <span id='btnMessage'>{t('ay_with_Pi')}</span>
                </button>
                <button id='backBtn' onClick={() => window.open(returnUrl)}>{t('Cancel')}</button>
              </span>
          }
          {
            paid &&
            <div style={{ textAlign: 'center' }}>
              <i style={{ display: 'inline-block', marginBottom: '20px', color: 'green' }}>{t('Payment_received')}</i>
              <button id='backBtn' onClick={() => window.open(returnUrl)}>{t('Return_to_vendor')}</button>
            </div>
          }
          {
            loading == true &&
            <div className='loader' style={{ margin: '0' }}></div>
          }
        </>
      }

      {
        success === true &&
        <div className="paymentSuccessful" id="paymentSuccessful">
          <br />
          <h3>{t('Transfer_Successful!')}</h3>
          <img className="completeImg" src={Complete} />
          <br /><br />
          <i>{t('Redirecting_in')} {seconds}</i>
          {
            !sandbox &&
            <div id='successTxid'>
              <p>{t('Transaction_ID')}: {txid}</p>
              <i className="fas fa-copy" id='copyTxid' onClick={() => { navigator.clipboard.writeText(txid); alert('Copied to clipboard!') }}></i>
            </div>
          }
        </div>
      }

      {
        success === false &&
        <>
          <div className="paymentSuccessful" id="paymentUnsuccessful">
            <br />
            <h3>{t('Transfer_Cancelled')}</h3>
            <img className="completeImg" src={Failed} />
            <p>{btnMessage}</p>
          </div>

          <button id='backBtn' onClick={() => window.open(returnUrl)}>{t('Return_to_vendor')}</button>
        </>
      }

      {
        !isPiBrowser &&
        <>
          <h3 style={{ padding: '20px' }}>{t('Please_open_this_page_in_the_Pi_Browser_to_complete_your_checkout.')}</h3>
          <p>{window.location.href.split('//')[1]} <a style={{ color: '#5B4AFB', cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(window.location.href); alert('Copied') }}>{t('Copy')}</a></p>
          <i>{t('or')}</i>
          <a style={{ color: '#5B4AFB', cursor: 'pointer' }} onClick={() => window.open(`pi://piecard.app/pay/${props.paymentId}`)}>{t('Re-attempt_launch')}</a>
        </>
      }
    </div>
  )
}

export default Pay;