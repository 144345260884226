import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withdraw } from "../app/payment";
import { addPassword, addWalletAddress } from "../app/routes";

export function SetUpAccount(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState('Link wallet');
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [wallet, setWallet] = useState("");
  const [walletCorrect, setWalletCorrect] = useState(true);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [userIsNew, setUserIsNew] = useState(true);
  const [stage, setStage] = useState(0);

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    setUsername(profile.username);
    const dateCreated = Date.parse(profile.date);
    const currentDate = Date.parse(new Date());
    const diff = (currentDate - dateCreated) / (1000 * 60 * 60 * 24);
    if (diff > 1) setUserIsNew(false);
    if (localStorage.wallet == 'true' && Number(localStorage.balance) === 0) {
      setStatus('Add password');
      setStage(3);
    } else if (localStorage.wallet == 'true' && Number(localStorage.balance) > 0) {
      setStatus('Withdraw')
      setStage(2);
    }
  }, []);

  const handleAddWallet = async () => {
    if (wallet.split(" ").length > 1) return alert("Please check this address. Ensure you do NOT enter your private key or passphrase.");
    setWalletCorrect(null);
    setStatus('Loading');
    const result = await addWalletAddress(wallet);
    console.log(Number(localStorage.balance))
    if (result) {
      setWalletCorrect(true);
      localStorage.setItem("wallet", 'true');
      if (localStorage.password == 'true' && Number(localStorage.balance) == 0) {
        setTimeout(() => props.close(), 1000);
      } else if (Number(localStorage.balance) > 0.001) {
        setStage(2);
        setStatus('Withdraw');
      } else {
        setStage(3);
        setStatus('Add password');
      }
    } else {
      setWalletCorrect(false);
      setStatus('Link wallet');
    }
  };

  const handleWithdrawal = async () => {
    setStatus('Loading...');
    setLoading(true);
    console.log(Number(localStorage.balance))
    const result = await withdraw(Number(localStorage.balance), wallet);
    if (result) {
      setLoading(false);
      setStatus('Done!');
      if (localStorage.password != 'true') setTimeout(() => { setStage(3); setStatus('Add password') }, 1000);
      else setTimeout(() => props.close(), 1000);
    } else {
      setLoading(false);
      setStatus('Error!');
      setTimeout(() => setStatus('Retry'), 2000);
    }
  };

  const handleAddPassword = async () => {
    if (password === confirmPassword) {
      setStatus('Loading');
      await addPassword(password);
      setStatus('Done!');
      setTimeout(() => props.close(), 1000);
    } else alert('Passwords do not match');
  };

  return (
    <>
      <div id="addPassword">

        {
          stage === 0 &&
          <>
            <h2 style={{ color: 'black' }}>{t('Welcome')} {!userIsNew && t('back')}, {username}!</h2>
            <p>{t('on_chain_message')}</p>
            <br /><br /><br />
            <button id="addPasswordButton" onClick={() => setStage(1)}>
              {t("Next")}
            </button>
          </>
        }

        {
          stage === 1 &&
          <>
            <h3>{t('Link_a_wallet_to_your_Pi_eCard_account')}</h3>
            <i>{t("Please_note,_this_must_be_a_KYC'd_Mainnet_Address._You_will_receive_all_future_transactions_directly_to_this_address.")}</i><br />
            {walletCorrect == true && <input type="text" placeholder={t('Enter_Public_Wallet_Address')} onChange={e => setWallet(e.target.value)} />}
            {walletCorrect == null &&
              <>
                <div className="loader"></div>
              </>
            }
            {walletCorrect == false &&
              <>
                <input type="text" placeholder={t('Enter_Public_Wallet_Address')} onChange={e => setWallet(e.target.value)} />
                <p style={{ color: 'red' }}>{t('Invalid_address')}</p>
              </>
            }
            <br /><br />
            <span>
              <button id="addPasswordButton" onClick={handleAddWallet}>
                {status}
              </button>
              <button id="skipPasswordButton" onClick={() => setStage(4)}>
                {t("I'm_not_KYC'd")}
              </button>
            </span>
          </>
        }

        {
          stage === 2 &&
          <>
            <h3>{t('Withdraw_your_Pi_eCard_balance')}</h3><br /><br />
            <p>{t('Your_Pi_eCard_balance_is_no_longer_needed,_as_all_future_transactions_will_be_paid_straight_from_your_wallet.')}</p><br />
            <b>{localStorage.balance} Pi</b>
            <i>{wallet}</i><br />
            {
              loading ?
                <><div className="loader"></div><p style={{ textAlign: 'center', marginTop: '10px' }}>{t('Loading,_please_do_not_close_the_app')}</p></> :
                <span>
                  <button id="addPasswordButton" onClick={handleWithdrawal}>
                    {status}
                  </button>
                  {/* <button id="skipPasswordButton" onClick={() => { if (localStorage.password != 'true') setStage(3); else props.close() }}>
                    {t('Maybe_Later')}
                  </button> */}
                </span>
            }
          </>
        }

        {
          stage === 3 &&
          <>
            <h3>{t('Add_a_password_to_your_account')}</h3><br /><br />
            <input type="password" placeholder={t('Password')} onChange={e => setPassword(e.target.value)} />
            <input type="password" placeholder={t('Confirm_Password')} onChange={e => setConfirmPassword(e.target.value)} />
            <i>{t('This_will_allow_you_to_use_Pi_eCard_outside_of_the_Pi_Browser')}.</i>
            <span>
              <button id="addPasswordButton" onClick={handleAddPassword}>
                {status}
              </button>
              <button id="skipPasswordButton" onClick={props.close}>
                {t('Maybe_Later')}
              </button>
            </span>
          </>
        }

        {
          stage === 4 &&
          <>
            <h3>{t("You_must_be_KYC'd.")}</h3><br /><br />
            <p>{t("We're_sorry,_but_without_a_Mainnet_wallet,_you_will_be_unable_to_send_or_receive_Pi.")}</p>
            <br /><br /><br />
            <span>
              <button id="addPasswordButton" onClick={props.close}>
                {t('Close')}
              </button>
              <button id="skipPasswordButton" onClick={() => setStage(1)}>
                {t("Go_back")}
              </button>
            </span>
          </>
        }

      </div>
      <div id='noticeTint'></div>
    </>
  );
}

export function HackathonNotice(props) {
  const handleCopy = () => {
    navigator.clipboard.writeText('https://brainstorm.pi/project/63bd8a990f037d47b342dff9');
    alert('Copied');
  }

  return (
    <>
      <div id="hackathonNotice">
        <h2 style={{ marginBottom: '15px' }}>Notice</h2>
        <p>
          Pi eCard is participating in the{" "}
          <a href="https://minepi.com/hackathon-instructions" target="_blank" rel="noreferrer">
            2023 Pi Network Hackathon
          </a>
          ! <br /><br /> Check out our Brainstorm submission{" "}
          <a onClick={() => window.open("pi://brainstorm.pi/project/63bd8a990f037d47b342dff9")} target="_blank" rel="noreferrer">
            here
          </a>
          , or copy and paste this link into your Pi Browser: <br /> <i onClick={handleCopy}>https://brainstorm.pi/project/63bd8a990f037d47b342dff9</i>
          <br /><br />
          Please show your support with a thumbs up!
        </p>
        <button id="closeHackathonNotice" onClick={props.close}>
          Close
        </button>
      </div>
      <div id='noticeTint' onClick={props.close}></div>
    </>
  );
}