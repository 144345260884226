import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Info(props) {
  const { t } = useTranslation();

  return (
    <div className="modal" id="info">
      <div className="modal-dialog info-content">
        <header style={{ color: '#b44afb' }} className="modal-header info-content">
          <h3>{t('Welcome_to_Pi_eCard')}
            <button className="close-modal" style={{ width: '40px' }} onClick={() => props.close()}>✕</button>
          </h3>
        </header>
        <section className="modal-content info-content">
          <br />
          <h4>{t('What_is_Pi_eCard')}?</h4><br />
          <p3> {t('info_1')} </p3><br /><br /><br />

          <h4>{t('info_2')}:</h4><br />
          <ol>
            <li>
              {t('info_3')}
            </li>
            <li>
              {t('info_4')}
            </li>
            <li>
              {t('info_5')}
            </li>
            <li>
              {t('info_6')}
            </li>
          </ol><br /><br />

          <h4>{t('info_7')}</h4><br />
          <ol>
            <li>
              {t('info_8')}
            </li>
            <li>
              {t('info_9')}
            </li>
            <li>
              {t('info_10')}
            </li>
            <li>
              {t('info_11')}
            </li>
            <li>
              {t('info_12')}
            </li>
          </ol>
        </section>
      </div>
    </div>
  );
}

export default Info;