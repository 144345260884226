import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import History from './History';
import { withdraw } from '../app/payment';
import PiLogo from '../assets/pi.png';
import Logo from '../assets/logoLarge.png';
import AccountImg from '../assets/account.png';
import Invoices from './Invoices.js';
import BuyPi from './modals/BuyPi.js';

export function AccountIcon(props) {
  const { t } = useTranslation();

  return (
    <div onClick={props.open} className="box inactive">
      <h3 style={{ margin: '1px 0', fontSize: '17px' }}>{t('Account')}</h3>
      <img id="iconC" src={AccountImg} style={{ display: 'block', height: '32px', margin: '3px auto' }}></img>
    </div>
  );
}

function UserQRCode() {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  const createQR = async () => {
    setModal(true);
    setTimeout(async () => {
      await new window.QRCode(document.getElementById("qrcode"), {
        text: `u/${localStorage.username}`,
        width: 170,
        height: 170,
        colorDark: "#000",
        colorLight: "#ffffff",
        correctLevel: window.QRCode.CorrectLevel.H
      });
    }, 100);
    // document.getElementById("qrcode").style.background = "#fff";
    // document.getElementById("qrcode").style.margin = "auto";
  };

  return (
    <>
      <div className="accountQR">
        <span>{t('Create_a_payable_QR_code')}:</span>
        <button onClick={createQR} id="createQR" className='fas fa-qrcode'></button>
      </div>

      {
        modal &&
        <>
          <div id='userQRModal'>
            <div id="qrcode"></div>
          </div>
          <div id='tint' onClick={() => setModal(false)}></div>
        </>
      }
    </>
  );
}

function Account() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('loading..');
  const [balance, setBalance] = useState('loading..');
  const [invoices, setInvoices] = useState(false);
  const [buyModal, setBuyModal] = useState(false);
  const [topupAmount, setTopupAmount] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [success, setSuccess] = useState(null);
  const [status, setStatus] = useState('request pending...');
  const [hasPassword, setHasPassword] = useState(false);
  const [topupLoading, setTopupLoading] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  useEffect(() => {
    if (localStorage.username && localStorage.username !== 'null') setUsername(localStorage.username);
    else setUsername('Please login');
    if (localStorage.balance && localStorage.balance !== 'null') setBalance(localStorage.balance);
    else setBalance('0');
    window.addEventListener('auth', function () {
      if (localStorage.username && localStorage.username !== 'null') setUsername(localStorage.username);
      else setUsername('Please login');
      if (localStorage.balance && localStorage.balance !== 'null') setBalance(localStorage.balance.toString());
      else setBalance('0');
    });
    let password;
    if (localStorage.profile && localStorage.profile !== 'null') password = JSON.parse(localStorage.profile).password;
    if (password) setHasPassword(true);
  }, []);

  const maxWithdrawal = () => {
    document.getElementById("withdrawAmount").value = balance;
    setWithdrawAmount(balance);
  }

  const handleWithdrawal = async () => {
    setWithdrawLoading(true);
    await withdraw(withdrawAmount);
    setWithdrawLoading(false);
  }

  return (
    <>
      <div id='c' className='box active'>
        {
          balance > 0 &&
          <>
            <h3>{t('Account')}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>

              <div className='accountBalance' style={{ marginTop: '20px' }}>
                <p style={{ fontSize: '18px', padding: '5px 10px' }}>{t('username')}: {username}</p>
                <span style={{ fontSize: '18px', padding: '5px 10px', display: 'flex' }}>
                  <img style={{ height: '25px', marginRight: '5px' }} src={PiLogo}></img>
                  <p>{t('balance')}:</p>
                  <p style={{ margin: '0 10px', fontSize: '25px' }}>{balance} Pi</p>
                </span>
              </div>

              <div className="accountBalance">
                <a className="subheading">{t('withdraw_Pi_to_wallet')}:</a>
                <form action="/withdraw" method="POST">
                  <input className="field" id='withdrawAmount' type="number" placeholder={t('Enter_amount') + '..'} onChange={(e) => setWithdrawAmount(e.target.value)}
                    required></input>
                  <input type="button" id="max" value={t('max')} onClick={maxWithdrawal}></input>
                  <div id="invoice_btn" onClick={handleWithdrawal}>{!withdrawLoading ? t('withdraw') : <span className='btnLoader'></span>}</div>
                </form>
              </div>

              <br /><br />
            </div>
          </>
        }
        <UserQRCode />
        <History />

      </div>

      {invoices && <Invoices close={() => setInvoices(false)} />}
      {buyModal && <BuyPi success={success} status={status} close={() => setBuyModal(false)} />}
    </>
  );
}

export default Account;