import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Complete from '../../assets/complete.png';
import Failed from '../../assets/failed.png';
import { checkAuthed, payScanned } from '../../app/routes.js';
import { createPayment, payByUsername } from '../../app/payment.js';

function PayQR(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState('waiting for camera to open...');
  const [success, setSuccess] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [payToAccount, setPayToAccount] = useState(false);
  const [username, setUsername] = useState('');
  const [amount, setAmount] = useState(0);

  const handleCancel = () => {
    setStatus(t('cancelled'));
    setSuccess(false);
    setConfirmed(true);
  };

  const handlePay = async () => {
    checkAuthed();
    setStatus(t('making_transfer...'));
    setConfirmed(true);
    await payByUsername(amount, username, '', setStatus, setSuccess);
  };

  useEffect(() => {
    const video = document.createElement("video");
    const canvasElement = document.getElementById("qr-canvas");
    const canvas = canvasElement.getContext("2d");
    const qrResult = document.getElementById("qr-result");

    let scanning = false;

    window.qrcode.callback = res => {
      if (res) {
        if (res.includes('u/')) {
          setPayToAccount(true);
          setUsername(res.split('u/')[1]);
        } else payScanned(res, setStatus, setSuccess, setScanned, setConfirmMessage);
        scanning = false;

        video.srcObject.getTracks().forEach(track => {
          track.stop();
        });

        qrResult.hidden = false;
        canvasElement.hidden = true;
        setStatus(t('QR_code_found,_processing...'));
      }
    };

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then(function (stream) {
        scanning = true;
        qrResult.hidden = true;
        canvasElement.hidden = false;
        setStatus(t("Scanning_for_QR_code..."));
        video.setAttribute("playsinline", true);
        video.srcObject = stream;
        video.play();
        tick();
        scan();
      });
    const scanningError = setTimeout(scanningErr, 2000);

    function scanningErr() {
      if (scanning === false) {
        setStatus(t("Camera_is_blocked._Please_enable_the_camera_permission_for_the_Pi_Browser_in_your_phone's_settings."));
      }
    }

    function tick() {
      canvasElement.height = video.videoHeight;
      canvasElement.width = video.videoWidth;
      canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);

      scanning && requestAnimationFrame(tick);
    }

    function scan() {
      try {
        window.qrcode.decode();
      } catch (e) {
        setTimeout(scan, 300);
      }
    }
  }, []);

  return (
    <>
      <div className='modalContainer'>
        <div className='payModal'>
          <header className="modal-header">
            <h3>{t('Transfer')}
              <button className="close-modal"
                style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
                onClick={() => props.setPayQR(false)}>✕</button>
            </h3>
          </header>
          <br /><br />
          <section className="modal-content">
            {success === true &&
              <div className="paymentSuccessful" id="paymentSuccessful">
                <br />
                <h3>{t('Transfer_Successful')}!</h3>
                <img className="completeImg" src={Complete} />
              </div>
            }
            {
              success === false &&
              <div className="paymentSuccessful" id="paymentUnsuccessful">
                <br />
                <h3>{t('Transfer_Cancelled')}</h3>
                <img className="completeImg" src={Failed} />
              </div>
            }
            {scanned === false && <canvas hidden="" id="qr-canvas"></canvas>}
            {
              (scanned === true && confirmed === false) &&
              <div id="confirmPaymentQR">
                <a id="confirmPaymentQRMessage">{confirmMessage}</a>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <button id="confirmPaymentQRBtn" onClick={() => createPayment(setConfirmed, setStatus, setSuccess)}>{t('Yes')}</button>
                  <button id="cancelPaymentQRBtn" onClick={handleCancel}>{t('No')}</button>
                </div><br /><br />
              </div>
            }
            {
              (payToAccount && !confirmed) &&
              <div>
                <a id="confirmPaymentQRMessage">{t('Send_to') + " '" + username + "'"}</a><br /><br />
                <input className="field" style={{ border: 'solid 1px #ccc', width: 'calc(100% - 20px)', marginLeft: 'unset' }} type='number' onChange={(e) => setAmount(e.target.value)} placeholder={t('Amount')} /><br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <button id="confirmPaymentQRBtn" onClick={handlePay}>{t('Pay')}</button>
                  <button id="cancelPaymentQRBtn" onClick={() => props.setPayQR(false)}>{t('Cancel')}</button>
                </div><br /><br />
              </div>
            }
            <div id="qr-result" hidden=""></div><br /><br />
            <a className="status">{t('Status')}: {status}</a>
          </section>
        </div>
      </div>

      <div onClick={() => props.setPayQR(false)} id='darkTint'></div>
    </>
  );
}

export default PayQR;