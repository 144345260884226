import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Complete from '../../assets/complete.png';
import Failed from '../../assets/failed.png';
import { checkAuthed, checkUserWallet } from '../../app/routes.js';
import { payByUsername } from '../../app/payment';

function PayUsername(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(t('waiting_for_transfer_confirmation...'));
  const [success, setSuccess] = useState(null);
  const [confirmed, setConfirmed] = useState(null);

  useEffect(() => {
    checkUserWallet(props.username, setConfirmed, setStatus, setSuccess);
  }, []);

  const handlePay = async () => {
    checkAuthed();
    setStatus(t('making_transfer...'));
    setConfirmed(true);
    await payByUsername(props.amount, props.username, props.memo, setStatus, setSuccess);
  };

  const handleCancel = () => {
    setStatus(t('transfer_cancelled'));
    setConfirmed(true);
    setSuccess(false);
  };

  return (
    <>
      <div className='modalContainer'>
        <div className='payModal' style={{ height: '400px' }}>
          <header className="modal-header">
            <h3>{t('Transfer')}
              <button className="close-modal"
                style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
                onClick={() => props.setPayUsername(false)}>✕</button>
            </h3>
          </header>
          <section className="modal-content">
            <br /><br />
            {success === true &&
              <div class="paymentSuccessful" id="paymentUsernameSuccessful">
                <h3>{t('Transfer_Successful')}!</h3>
                <img className="completeImg" src={Complete}></img>
              </div>
            }
            {success === false &&
              <div className="paymentSuccessful" id="paymentUsernameUnsuccessful">
                <h3>{t('Transfer_Unsuccessful')}</h3>
                <img className="completeImg" src={Failed}></img>
              </div>
            }
            {confirmed === false &&
              <div id="confirmPaymentUsername">
                <a id="confirmPaymentUsernameMessage">{t('confirmPaymentUsernameMessage', {amount: props.amount, username: props.username})}</a><br /><br />
                <i id="confirmPaymentUsernameMessageFee">{t('confirmPaymentUsernameMessageFee', {fee: props.amount * 0.01})}</i>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <button id="confirmPaymentUsernameBtn" onClick={handlePay}>{t('Yes')}</button>
                  <button id="cancelPaymentUsernameBtn" onClick={handleCancel}>{t('No')}</button>
                </div><br /><br />
              </div>
            }
            {(confirmed === true && success == null) && <><div id="processingPaymentUsername" className="loader"></div><br /></>}
            {confirmed === null && <><div id="processingPaymentUsername" className="loader"></div><br /></>}
            <br /><a className="status">{t('Status')}: {status}</a>
          </section>
        </div>
      </div>

      <div onClick={() => props.setPayUsername(false)} id='darkTint'></div>
    </>
  );
}

export default PayUsername;