import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getInvoices } from '../app/routes';

function Invoice(props) {
  const { t } = useTranslation();
  const [hidden, setHidden] = useState(false);

  const deleteInvoice = async () => {
    const instance = axios.create({
      baseURL: "https://server.piecard.co.uk",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.userSession}`
      },
      withCredentials: true,
      credentials: "same-origin"
    });
    const response = await instance.delete(`/payment?id=${props.data._id}`);
    if (response.status == 200) setHidden(true);
    else alert("Failed to delete. Please try again later...");
  }

  return (
    <>
      {hidden === false &&
        <div key={props.data._id} className="renderedInvoiceDiv">
          <h4 className='renderedInvoiceTitle'>{(props.data.payer == null || props.data.payer == "") ? `${t('Request')} ${props.data.amount} Pi` : `${t('Requested')} ${props.data.amount} Pi ${t('from')} ${props.data.payer}`}</h4>
          <p className='renderedInvoiceMemo'>{props.data.memo !== '' && `Memo: ${props.data.memo}`}</p>
          <div className='renderedInvoiceInfoDiv'>
            <i className='renderedInvoiceDate'>{props.data.date.substring(0, 10)}</i>
            {props.data.status == true ? <i className='renderedInvoiceStatus statusTrue'>{t('Completed')}</i>
              : <i className='renderedInvoiceStatus'>{t('Pending')}...</i>}
            {props.data.status == true ? <a style={{ color: 'green' }} className="fas fa-check renderInvoiceButton"></a>
              : <a onClick={deleteInvoice} className="fas fa-trash renderInvoiceButton"></a>}
          </div>
        </div>
      }
    </>
  );
}

function Invoices(props) {
  const { t } = useTranslation();
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const findInvoices = async () => {
    const foundInvoices = await getInvoices();
    foundInvoices.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    setInvoices(foundInvoices);
    setLoading(false);
  }

  useEffect(() => {
    findInvoices();
  }, []);

  return (
    <>
      <div className="modalContainer" id="invoices">
        <div className="payModal">
          <header className="modal-header">
            <h3>{t('Invoices')}
              <button className="close-modal"
                style={{ width: '40px', color: '#5b4afb', background: 'none', fontSize: '20px', marginTop: '-8px' }}
                onClick={() => props.close()}>✕</button>
            </h3>
          </header><br />
          <section className="modal-content invoice-modal-content">
            <div style={{ marginTop: '-10px' }} id="invoicesDiv">
              {loading ? <><div className="loader"></div><br /></> : null}
              {invoices.map(invoice => {
                return <Invoice data={invoice} />
              })}
              {invoices.length == 0 && loading == false && <p style={{ textAlign: 'center', marginTop: '20px' }}>{t('You_have_made_no_invoices_yet')}</p>}
            </div>
          </section>
        </div>
      </div>

      <div onClick={() => props.close()} id='darkTint'></div>
    </>
  );
}

export default Invoices;