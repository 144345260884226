import React, { useState, useEffect } from 'react';
import '../styles/invoice.css';
import Complete from '../assets/complete.png';
import Failed from '../assets/failed.png';
import { payInvoice } from '../app/routes.js';
import { createPayment } from '../app/payment.js';

function Invoice(props) {
  const [date, setDate] = useState('loading...');
  const [recipient, setRecipient] = useState('loading...');
  const [amount, setAmount] = useState('loading...');
  const [memo, setMemo] = useState('');
  const [confirmed, setConfirmed] = useState(true);
  const [success, setSuccess] = useState(null);
  const [status, setStatus] = useState('Finding transaction...');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!props.paymentId) window.location.href = '/';
    payInvoice(props.paymentId, setSuccess, setStatus, setConfirmed, setAmount, setMemo, setRecipient, setDate);
  }, []);

  const handleCancel = () => {
    setConfirmed(true);
    setSuccess(false);
    setStatus('Transfer cancelled.');
    function goHome() {
      window.location.href = '/';
    }
    setTimeout(goHome, 3000);
  }

  return (
    <>
      <div id="invoice">
        <div id='info'>
          <h1>Invoice</h1>
          <br />
          <p>Invoice ID: {props.paymentId}</p>
          <br />
          <p style={{ float: 'right' }}>{date}</p>
          <p>Billed to: {recipient}</p>
          {memo !== '' && <><br /><p>{memo}</p></>}
          <br /><br /><br />
          <h2>Total: {amount} Pi</h2>
        </div>
        <br /><br />

        {
          confirmed === false &&
          <div id='confirm'>
            <div id="confirmPayment">
              <p>Enter your Pi eCard account details and confirm tranfer</p>
              <br />
              {
                sessionStorage.userSession ?
                  <i>Your Pi eCard balance is {localStorage.balance} Pi</i> :
                  <>
                    <input id="username_input" placeholder="Username" type="text" onChange={(e) => setUsername(e.target.value)} />
                    <input id="password_input" placeholder="Password" type="password" onChange={(e) => setPassword(e.target.value)} />
                  </>
              }
              <div id='confirmInvoiceOptions'>
                <button id="payInvoiceBtn" onClick={() => createPayment(setConfirmed, setStatus, setSuccess, username, password)}>Send</button>
                <button id="cancelInvoiceBtn" onClick={handleCancel}>Cancel</button>
              </div><br /><br />
            </div>
          </div>
        }

        {
          success === true &&
          <div className="paymentSuccessful" id="paymentSuccessful">
            <br />
            <h3>Transfer Successful!</h3>
            <img className="completeImg" src={Complete} /> <br /><br />
            <button id="invoicePayComplete" onClick={() => window.location.href = '/'}>Home</button>
          </div>
        }
        {
          success === false &&
          <div className="paymentSuccessful" id="paymentUnsuccessful">
            <br />
            <h3>Transfer Cancelled</h3>
            <img className="completeImg" src={Failed} />
          </div>
        }

        <p id='status'>Status: {status}</p>
      </div>
    </>
  );
}

export default Invoice;