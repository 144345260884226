import React from 'react';
import Logo from '../assets/logoWhite.png';

class NoPage extends React.Component {
  render(){
    return (
      <>
        <span id="notFound" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
          <h2>404 - We couldn't find this page :(</h2>
          <img src={Logo} style={{ height: '150px', width: '150px' }} />
        </span>
      </>
    );
  }
}

export default NoPage;